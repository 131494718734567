import React, { useState } from 'react';
import axios from 'axios';
import '../styles/Chatbot.css';

const Chatbot = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Website-specific context (replace with your actual website details)
  const WEBSITE_CONTEXT = `
  You are a helpful chatbot for Cannon IT Solutions. 
  Key information about our website:
  Cannon IT Solutions is a digital transformation company founded in 2021. They offer a range of services including website development, app development, digital marketing, and business analytics. The company states that their mission is to empower businesses of all sizes with transformative digital solutions, bridging the gap between complex technology and practical business needs.
The company has a team of four key individuals - Dipesh Danuwar (CEO & Founder), Nitesh Tripathi (CTO), Sumi Timilsina (Lead Designer), and Ramesh Chapagain (Senior Developer). Each team member has relevant expertise and experience that contributes to the company's capabilities.
Some key information about Cannon IT Solutions:
the services offered by Cannon IT Solutions, which include:

Website Development - Custom-built, responsive websites tailored to business needs.
App Development - Innovative mobile and web applications for various platforms.
Digital Marketing - Strategic online marketing to boost your brand's digital presence.
Business Analytics - Data-driven insights to inform your business decisions.

Over 50 satisfied clients
More than 100 projects completed
24/7 support available
Located in Columbia, South Carolina

Contact information, including an email address: cannonitisolutions@gmail.com:, a phone number (+1 (318) 503-7293), and a physical address (127 Sparkleberry Ln, Columbia, SC, 29229). Social media links to Facebook, LinkedIn, and Instagram are also included.

  When answering questions, always:
  - Provide accurate information about the website
  - Be helpful and friendly
  - Redirect irrelevant questions to website-specific content
  - If you donot know any answers ask them to contact the company giving their email, phone , and address

  `;

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const sendMessage = async () => {
    if (!input.trim()) return;

    // Add user message to chat
    const userMessage = { 
      text: input, 
      sender: 'user' 
    };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInput('');
    setIsLoading(true);

    try {
      // Construct a contextual prompt
      const contextualPrompt = `${WEBSITE_CONTEXT}

      User Question: ${input}
      
      Helpful Response:`;

      // Hugging Face API call
      const response = await axios.post(
        'https://api-inference.huggingface.co/models/EleutherAI/gpt-neo-2.7B',
        { 
          inputs: contextualPrompt,
          parameters: {
            max_new_tokens: 250,  // Limit response length
            temperature: 0.7,     // Control randomness
            top_p: 0.9            // Control diversity
          }
        },
        {
          headers: {
            Authorization: `Bearer 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`,
          },
        }
      );
      
      // Extract and clean the bot response
      const rawResponse = response.data[0]?.generated_text || 'No response';
      const botResponse = rawResponse
        .replace(contextualPrompt, '')  // Remove original prompt
        .split('User Question:')[0]     // Remove any additional context
        .trim();

      // Add AI response to chat
      const aiMessage = { 
        text: botResponse || 'I apologize, but I could not generate a helpful response.',
        sender: 'ai' 
      };
      setMessages(prevMessages => [...prevMessages, aiMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = { 
        text: 'Sorry, I\'m having trouble processing your request. Please try again.', 
        sender: 'ai' 
      };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="floating-chatbot-container">
      {/* Chat Icon */}
      <div 
        className={`chat-icon ${isChatOpen ? 'active' : ''}`} 
        onClick={toggleChat}
      >
        {isChatOpen ? '✕' : '💬'}
      </div>

      {/* Chat Dialog */}
      {isChatOpen && (
        <div className="chat-dialog">
          <div className="chat-header">
            <h3>Website Assistant</h3>
            <button onClick={toggleChat}>−</button>
          </div>

          <div className="message-list">
            {messages.map((message, index) => (
              <div 
                key={index} 
                className={`message ${message.sender}`}
              >
                {message.text}
              </div>
            ))}
            {isLoading && (
              <div className="message ai">
                Generating response...
              </div>
            )}
          </div>

          <div className="input-area">
            <input 
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
              placeholder="Ask about our website..."
            />
            <button onClick={sendMessage}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;